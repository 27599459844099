.form-title-header {
  display: inline-block;
  .title-edit-button.close {
    margin-left: 0.6rem;
    .fas {
      color: white;
    }
    opacity: 1;
    &:focus {
      opacity: 1;
    }
  }

  .title-edit-input {
    font-size: 1.25rem;
    display: inline-block;
    width: auto;
    min-width: 180px;
    border-radius: 4px;
    font-weight: 600;
    height: 30px;
    background-color: transparent;
    color: white;
    padding-left: 2px;
    &:focus {
      background-color: transparent;
      color: white;
      border-color: white;
    }
  }
}

.color-input-container {
    position: relative;

    .color-input {
        cursor: pointer;
    }
    .color-box {
        cursor: pointer;
        padding: 3px;
        border-radius: 5px;
        box-shadow: 0 0 0 1px rgba(0,0,0,.1);
        width: 45px;
        height: 1.3rem;
        display: inline-block;
        position: absolute;
        right: 5px;
        top: 5px;
        .color {
            width: 100%;
            height: 100%;
            border-radius: 2px;
        }
    }
    .sketch-picker {
        position: absolute;
        z-index: 5;
    }
}
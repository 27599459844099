.password-requirements {
  list-style: none;
  padding-left: 0.7rem;
  font-size: 12px;
}

.password-requirements .requirement-item {
  color: #688eb9;
}

.password-requirements .requirement-item .fa {
  margin-right: 0.3rem;
}

.user-registration-card .form-group .form-control {
  letter-spacing: 1px;
}

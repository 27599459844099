.main-content {
    height: 100vh;
    display: flex;
    flex-direction: column;

    .footer {
        padding: 10px 0px;
    }
}

.font-size-14 {
    font-size: 14px;
}

.account-border {
    border-left: 4px solid;
}
.sinapi-plan-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.sinapi-plan-container .sinapi-plan {
  border: 1px solid #dcdcdc;
  border-radius: 0.375rem;
  padding: 0px 2rem;
}

.sinapi-plan .plan-features {
  font-size: 0.8rem;
}
.sinapi-plan .plan-features .icon-shape {
  padding: 10px;
}

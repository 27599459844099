body {
  counter-reset: sectionList;
}

ul#sectionList > li.list-group-item::before {
  counter-increment: sectionList;
  content: counter(sectionList);
  height: 23px;
  width: 23px;
  border-radius: 23px;
  text-align: center;
  line-height: 22px;
  display: inline-block;
  margin-right: 8px;
  font-weight: bold;
  font-size: 0.8em;
  color: #aaa;
  position: relative;
  bottom: 1px;
}
ul#sectionList > li.list-group-item.bg-gradient-success::before {
  background-color: #fff;
  color: #2dce89;
}
ul#sectionList > li.list-group-item {
  cursor: pointer;
}
ul#sectionList > li.list-group-item:hover {
  background-color: #eee;
}
div.form-row:hover .row-toolbar,
.row-toolbar:hover {
  display: block;
}

.form-section-content {
  position: relative;
}

.form-section-content .section-toolbar {
  position: absolute;
  top: 10px;
  right: 0px;
  .options-wrapper {
    background-color: grey;
  }
}

div.form-row .row-toolbar {
  position: absolute;
  cursor: pointer;
  display: none;
  white-space: nowrap;
  z-index: 3;
  min-height: 125px;
  height: 100%;
  border-right: 4px solid rgba(43, 190, 236, 1);
}

.row-toolbar,
.section-toolbar {
  .options-wrapper {
    position: absolute;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    overflow: hidden;

    .row-option-btn {
      width: 35px;
      color: white;
      height: 30px;
      text-align: center;
      line-height: 30px;
      vertical-align: middle;
      font-size: 16px;
      padding-left: 1px;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    .show-options {
      color: white;
      padding-left: 3px;
      cursor: pointer;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    &.left {
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
      width: 15px;
      height: 100%;
      left: -15px;
      border-left: 1px solid rgba(255, 255, 255, 0.5);
    }

    &.right {
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
      width: 35px;
      right: -35px;
      border-right: 1px solid rgba(255, 255, 255, 0.5);
    }
  }
  div.options-wrapper {
    &.left {
      div.row-option-btn,
      div.option-divider {
        display: none;
      }
    }
  }

  div.options-wrapper:hover {
    width: 35px;

    &.left {
      left: -35px;
    }

    div.show-options {
      display: none;
    }

    div.row-option-btn {
      display: block;
    }
  }
  .options-wrapper .option-divider {
    height: 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }

  .row-column-options {
    display: none;
    border: 1px solid;
    position: absolute;
    background: white;
    width: 270px;
    top: 28px;
    height: auto;
    padding: 6px 25px;
    border-radius: 10px;
    &.active {
      display: block;
    }
    .row:hover .column-option .column-filler {
      background-color: #ffd600;
    }
    .column-option {
      padding: 0px 4px;
      .column-filler {
        height: 22px;
        margin: 4px 0px;
        border-radius: 5px;
        background-color: gray;
      }
    }
  }
}
div.form-row div.question-toolbar {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  display: none;
  white-space: nowrap;
  z-index: 3;
}

div.form-question div.question-toolbar > div {
  display: inline-block;
  &.question-option {
    display: none;
  }
}
.field-option-btn {
  color: white;
  background: #fe9b05;
  min-width: 30px;
  height: 32px;
  text-align: center;
  line-height: 30px;
  vertical-align: middle;
  font-size: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

div.form-rows div.question-toolbar > div:hover {
  background: #cf7d01;
}
div.form-question:hover > div.question-toolbar {
  display: block;

  .question-option {
    display: inline-block;
  }
}

.form-question-overlay {
  display: none;
  text-align: center;
  z-index: 1;
}

div.form-question:hover > div.form-question-overlay {
  display: inline-block;
  background-color: black;
  cursor: pointer;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 25px);
  color: white;
  font-size: x-large;
  border-radius: 5px;

  i.far.fa-edit {
    padding-top: 35px;
  }

  i.far.fa-edit.tiny-overlay {
    padding-top: 15px;
  }

  &.form-question-overlay-selected {
    height: 100%;
  }
}

.form-question-selected {
  border: 3px solid #fe9b05;
  height: 100%;
  width: 100%;
  position: absolute;
  top: -5px;
  left: 0;
  border-radius: 7px;
}

div.form-rows,
div.formSection {
  position: relative;
}
div.questionDetails > label + span.form-text {
  margin-top: -0.4rem;
  margin-bottom: 0.8rem;
}

/* ************** Question Modal ************** */
div.newQuestionOption {
  cursor: pointer;
  position: relative;
  padding: 15px;
}
div.newQuestionOption::after {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 6px;
  opacity: 0.1;
}
div.newQuestionOption:hover::after {
  background-color: #777;
}

div#newForm-wrapper .card-header {
  background-color: #eee;
}

div.newQuestionOption div.custom-checkbox label,
div.newQuestionOption div.custom-radio label {
  margin-right: 15px;
  padding-top: 5px;
}

div.form-rows:empty::before {
  content: "- No Questions Added Yet -";
  color: #aaa;
  text-align: center;
  width: 100%;
  display: block;
}

div.form-rows div.form-inline .custom-control {
  margin-right: 10px;
}

.form-rows .form-row {
  position: relative;
}

div.form-inline .custom-control > .custom-control-label {
  height: auto;
}

ul#editTabNav {
  height: auto;
  background-color: #f1f1f1;
  line-height: 25px;
  padding-bottom: 2px;
}
ul#editTabNav > li > a.nav-link {
  border: none;
  background: transparent;
  opacity: 0.9;
  cursor: pointer;
  padding-top: 0.42rem;
  padding-left: 0.47rem;
  padding-right: 0.47rem;
  padding-bottom: 0.25rem;
}

ul#editTabNav > li > a.nav-link.active,
ul#editTabNav > li > a.nav-link:hover {
  opacity: 1;
  background-color: #2bbeec;
  color: white;
  border-radius: 0;
  height: 104%;
}

/* ***** EDIT OPTIONS ******* */

div.optionItem {
  border-bottom: 1px solid #eee;
  margin-top: 10px;
  box-shadow: unset;
}
div.optionHeader {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 10px;
}
div.optionBody {
  cursor: default;
  background: #eee;
}
div.optionHeader > div {
  flex: 1;
}

div.optionHeader > div.name {
  flex: 100%;
}

div.optionHeader > div.nameSuccess {
  flex: 75%;
}

div.optionHeader > div.buttons {
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
}
div.optionHeader > div.buttons > button {
  margin: 0px;
  border-right: 1px solid #eee;
}
div.optionHeader > div.buttons > button:last-of-type {
  border-right: none;
}

.section-drop-area {
  padding: 50px 0px;
  margin: 10px 0px;
}

.field-drop-area,
.row-drop-area,
.section-drop-area {
  font-size: 16px;
  height: 105px;
  border: 2px dashed grey;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.field-drop-area:only-child {
  margin: 15px;
}

// Form Row CSS
.form-row-columns,
.form-row-columns > div {
  min-height: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 768px) {
  .form-row-columns,
  .form-row-columns > div {
    display: block;
  }
}

// Form Column CSS
.form-column-questions {
  min-height: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > div {
    height: 100%;
  }
}

.button-white > span:not(.sr-only) {
  color: white !important;
  font-size: 1.5rem;
}

.alert-container {
  position: fixed;
  width: 100%;
  z-index: 200;

.alert-header-bar {
    // vertically aligns text
    line-height: 40px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 20px 50px;
    padding: 4px
  }
}
  
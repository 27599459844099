.note-btn {
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  display: inline-block !important;
  height: 24px !important;
  padding: 3px 5px !important;
  width: unset !important;
  color: #525f7f !important;
}

.note-toolbar {
  border-bottom: 1px solid #ccc;
}

.note-icon-html-editor:before {
  content: "\EA14";
}

.note-toolbar .note-icon-caret {
  display: none;
}

.note-editor.note-frame.codeview .note-editing-area .note-codable {
  background-color: #fff;
}

.note-modal .modal-title {
  display: none;
}

.note-modal .note-btn.note-btn-primary.note-link-btn {
  color: #fff !important;
  background-color: #5e72e4 !important;
  border-color: #5e72e4 !important;
  height: 100% !important;
  padding: 10px !important;

}

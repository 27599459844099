.trial-header-bar {
  height: 40px;
  // vertically aligns text
  line-height: 40px;
  display: flex;
  font-size: 1.2rem;
  font-weight: bold;
  justify-content: center;

  .upgrade-btn {
    position: relative;
    font-size: 0.9rem;
    top: 4px;
    margin-left: 20px;
    padding: 1px 10px;
    height: 30px;
  }
}

.vertical-sidenav-container {
  position: absolute;
  z-index: 99;
  height: 100%;
  background-color: #f7f7f7;
  border: 1px solid #d0d0d0;

  &.open .vertical-sidenav-panel-wrapper {
    width: 450px;
    z-index: 99;
  }

  .vertical-sidenav-panel-wrapper {
    overflow: hidden;
    position: absolute;
    right: 0px;
    width: 0px;
    height: 100%;

    &.show-shadow {
      box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.35);
      transition: box-shadow 0.5s;
      transition-delay: 1s;
    }
  }

  &.left {
    left: 0px;
    .vertical-sidenav-panel-wrapper {
      left: 0px;
    }
    .vertical-sidenav-panel {
      left: -450px;
      &.open {
        left: 0px;
      }
    }
    .vertical-sidenav-buttons .nav-icon-button {
      margin-left: 0px;
      float: left;

      &:hover {
        text-decoration: none;
        background-color: #efefef;
        border: 1px solid #d0d0d0;
        width: 155px;
      }
    }
  }
  &.right {
    right: 0px;
    .vertical-sidenav-panel-wrapper {
      right: 0px;
    }
    .vertical-sidenav-panel {
      right: -450px;
      &.open {
        right: 0px;
      }
    }
    .vertical-sidenav-buttons .nav-icon-button {
      margin-right: 0px;
      float: right;

      &:hover {
        text-decoration: none;
        background-color: #efefef;
        border: 1px solid #d0d0d0;
        width: 155px;
      }

      &.disabled {
        opacity: 1;
        background-color: white;
        color: darkgrey;

        &:hover {
          background-color: white;
        }
      }
    }
  }

  .vertical-sidenav-buttons {
    width: 60px;

    .nav-icon-button {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 60px;
      height: 50px;
      font-size: 1rem;
      box-shadow: none;
      border-bottom: 1px solid #d0d0d0;
      background-color: white;
      overflow: hidden;
      border-radius: unset;
      padding: 3px 9px;
      text-align: left;

      i {
        font-size: 1.7rem;
        margin: auto;
        display: inline-block;
        width: 60px;
        text-align: center;
      }
      &:hover .nav-icon-button-title {
        display: inline-block;
      }
      .nav-icon-button-title {
        display: none;
        width:100%;
      }
    }
  }

  .vertical-sidenav-panel {
    height: 100%;
    position: absolute;
    width: 450px;
    transition: 1s;
  }
}

.sinapi-pr {
  border: 1px solid #d0cece;
  padding: 10px 16px;
  border-radius: 10px;
  text-align: center;

  .pr-footer {
    text-align: center;

    .sub-col-header {
      font-size: 1rem;
    }
  }

  &.current-plan {
    background-color: paleturquoise;
  }
}

.company-subscription-container {
  .sub-col-header {
    color: rgb(117, 117, 117);
    text-transform: uppercase;
    font-size: 0.9rem;
  }
  .sub-col-value {
    font-weight: bold;
  }
  .sub-option {
    font-size: 0.8rem;
  }
}

.pr-features {
  font-size: 0.9rem;

  li {
    border-top: 1px solid #ddd;
    padding: 5px;
  }
  li:first-child {
    border-top: 0;
  }
}

.sinapi-table-pagination {
  .page-item.disabled {
    opacity: 0.6;
  }
}

thead th.sort::after {
  content: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='%238898aa'/></svg>");
  margin-left: 1em;
}

thead th.ascending::after {
  font-family: "Font Awesome 5 Free";
  content: "\f077";
  display: inline-block;
  margin-left: 1em;
}

thead th.descending::after {
  font-family: "Font Awesome 5 Free";
  content: "\f078";
  display: inline-block;
  margin-left: 1em;
}

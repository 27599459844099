.theme-preview-toolbar {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  display: none;
  white-space: nowrap;
  z-index: 3;

  .field-option-btn:first-child {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .field-option-btn:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
}
.preview-card {
  overflow: unset !important;
}
.preview-theme-card:hover .theme-preview-toolbar {
  display: block;
}

.theme-preview-toolbar > div {
  display: inline-block;
}

.preview-theme-card-overlay {
  display: none;
  z-index: 1;
  cursor: pointer;
  background-color: black;
  opacity: 0.4;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
}

div.preview-theme-card:hover > div.preview-theme-card-overlay {
  display: inline-block;
}

.style-header {
  border-left: 4px solid #00A3FF;
  padding-left: 10px;
  margin-bottom: 13px;
  line-height: 1;
}

.updated-container {
  right: 10px;
  float: right;
  bottom: 5px;
  color: white;
  margin-top: 6px;
}

.add-new-section-row {
  .col {
    text-align: center;
    .btn {
      border-radius: 30px;
    }
  }
}
.form-section-content {
  .card {
    margin-bottom: 0px;
  }
}
.edit-container {
  flex: 1 1 auto;
  min-height: 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;

  &.left-panel-open {
    .section-container .centered-form {
      margin-left: 370px;
      transition: margin 1s;
    }
  }
  &.right-panel-open {
    .section-container .centered-form {
      margin-right: 370px;
      transition: margin 1s;
    }
  }
  .section-container {
    overflow-y: auto;
    overflow-x: hidden;
    width: calc(100% - 120px);
    justify-content: center;
    display: flex;
    flex-direction: row;
    .centered-form {
      transition: margin 1s;
      flex: 1 1 auto;
    }
  }

  .visualization-container {
    overflow-y: auto;
    overflow-x: hidden;
    width: calc(100% - 120px);
    p {
      margin-bottom: 0;
    }
    .visualization-card {
      width: 100%; 
      border-left: 6px solid #11cdef; 
      flex-basis: 100%; 
      overflow: hidden;
      box-shadow: 1px 1px rgb(0 0 0 / 10%);;
    }
    .visualization-button {
      color: #11cdef
    }
    .visualization-button:hover {
      color: white;
      background-color: #11cdef;
    }
    .question-header {
      border-bottom: 2px solid rgba(255, 255, 255, 1); 
      cursor: pointer; 
      border-radius: 0px !important;
      padding: 7px 16px;
      font-size: 14px;
      .close {
        font-size: 1.3em;
      }
    }
    .question-header:last-of-type{
      border-bottom: none; 
    }
    .question-details {
      p {
        font-size: 1em;
      }
      .form-control {
        font-size: 1em;
      }
    }
  }

  .save-options-container {
    position: absolute;
    right: 15px;
    .btn {
      padding: 0.625rem 0.8rem;
      .fas {
        font-size: 1.3rem;
      }
    }
  }

  .image-option {
    display: flex;
    cursor: pointer;
    label {
      display: flex;
    }
    padding: 0px;
    input[type=radio], input[type=checkbox] {
      margin:0;
      padding:0;
      -webkit-appearance:none;
      -moz-appearance:none;
      appearance:none;
    }
    label {
      flex-direction: column;
      cursor: pointer;
    }
    input[type=radio] + img {
      cursor: pointer
    }
    .image-wrapper {
      display: flex;
      flex: 75%;
      justify-content: center;
      cursor: pointer;
    }
  }

  .icon-picker {
    max-height: 400px;
    min-height: 50px;
    overflow: auto;
    .selected {
      background-color: #525f7f;
      color: #fff;
    }
    .icons {
      :hover {
        background-color: #525f7f;
        color: #fff;
        }
      }
    .icons {
      i {
        padding: 7px
      }
      cursor: pointer;
      max-height: 30px;
    }
  }
}

.custom-input-group-text {
  background-color: #e9ecef !important;
  span {
    font-size: .9rem !important;
  }
}

.btn-group-button {
  border: 1px solid #D9D9D9 !important;
  background-color: #fff !important;
}

.btn-group-button-active {
  background-color: #E8E8E8 !important;
}

.btn-group-button:hover {
  background-color: #E8E8E8 !important;
}

.justify-left {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-right {
  justify-content: flex-end;
}

.justify-space {
  justify-content: space-between;
}


.divider-field {
  & .width-rule {
    width: 115px;
  }
}

.btn-group-button {
  border: 1px solid #D9D9D9 !important;
  background-color: #fff !important;
}

.btn-group-button-active {
  background-color: #E8E8E8 !important;
}

.btn-group-button:hover {
  background-color: #E8E8E8 !important;
}

.divider-options {
  & .divider-thickness {
    & span {
      font-size: 14px !important;
      color: #686868 !important;
      display: flex;
      justify-content: center;  
      width: 47px;
    }
    & .input-group-text {
      background: #FBF9F9 !important;
    }
  }
  & .btn-group-background, .btn-group-background:hover {
    font-size: 14px !important;
    color: #686868 !important;
    font-weight: normal !important;
    background: #FBF9F9 !important;
    border-right:solid #D9D9D9 1px;
    border-top:solid #D9D9D9 1px;
    border-bottom:solid #D9D9D9 1px;
    border-radius: 0 !important;
  }
  & .show {
    & > .btn-group-background {
      font-size: 14px !important;
      color: #686868 !important;
      font-weight: normal !important;
      background: #FBF9F9 !important;
      border-right:solid #D9D9D9 1px;
      border-top:solid #D9D9D9 1px;
      border-bottom:solid #D9D9D9 1px;
      border-radius: 0 !important;
      border-color: #D9D9D9 #D9D9D9 #D9D9D9 #fff !important;
    }
  }
  & .custom-divider-width {
    width: 115px;
  }
}

.input-w-90 {
  width: 90% !important;
}
body {
  background-color: #dee8ee;
}

.card {
  box-shadow: 0 0 11px 1px rgba(136, 152, 170, 0.35);

  .card-body {
    padding: 25px;
  }
}

.chart {
  height: unset !important;
}

.form-control-sub-label {
  color: #525f7f;
  font-size: 0.675rem;
}

input[type="password"].form-control {
  font: 0.875rem Verdana, sans-serif;
  letter-spacing: 1px;
}

.number-without-arrows::-webkit-outer-spin-button,
.number-without-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-without-arrows[type=number] {
  -moz-appearance: textfield;
}

.main-logo {
  background-image: url("assets/img/brand/sinapi-logo.svg");
  background-repeat: no-repeat;
  max-width: 205px;
  min-height: 77px,;
  margin: 0px auto 0px;
  display: block;
}

@media (min-width: 768px) {
  .modal-xl {
    max-width: 500px;
  }
}
@media (min-width: 992px) {
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1268px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.btn-outline-primary,
.btn-outline-secondary {
  border: unset;
}

.bg-vertical-gradient-info {
  background: linear-gradient(180deg, #11cdef 0, #1171ef 100%) !important;
}

// SideBar
.navbar-vertical.navbar-expand-xs .navbar-nav {
  .active {
    background-color: rgba(255, 255, 255, 0.085);
  }
  .nav-item > .nav-link.active {
    background-color: unset;
  }
}

// Vertical SideNav
.vertical-sidenav-full-container {
  height: 100%;

  .card {
    height: 100%;

    .card-header {
      border-radius: 0;
      background: linear-gradient(87deg, #212529 0, #212229 100%);
    }

    .card-body {
      overflow: auto;
    }

    .card-separator {
      margin-bottom: 19rem;
    }

    .card-footer-sidenav {
      border-radius: 0;
      background: linear-gradient(87deg, #adb5bd 0, #adaebd 100%);
    }
  }
}

.vertical-sidenav-header {
  h1 {
    color: white;
  }
  button.close {
    span {
      color: white;
      font-size: 2.5rem;
      margin-top: 10px;
    }
  }
}

.hr {
  text-align: center;

  .chain-icon {
    position: relative;
    top: -25px;
    float: right;
    padding-left: 5px;
    padding-right: 8px;
    background-color: white;
    color: rgba(0, 0, 0, 0.2);
  }

  select,
  input {
    position: relative;
    top: -30px;
    background-color: white;
  }
}

// Carousel Indicators
.carousel-indicators--round li {
  border-radius: 10px;
  margin: 1px 3px;
  height: 15px;
  max-width: 15px;
  border: 1px solid grey;
  background-color: white;
}
.carousel-indicators .active {
  border: 1px solid rgba(255, 255, 255, 0.85);
  background-color: rgba(0, 0, 0, 0.5);
  max-width: 17px;
  margin: 0 3px;
  height: 17px;
}

.carousel-fade .carousel-item {
  display: block;
  position: absolute;
  opacity: 0;
}
.carousel-fade .carousel-item.active {
  opacity: 1;
  position: relative;
  transition: opacity 0.2s ease-in;
}

// Form preview container
.sample-container {
  opacity: 0;

  &.enter-done {
    opacity: 1;
    transition: opacity 1s ease-in;
  }
}

// RichTextEditor
.rich-text-editor-text p {
  font-weight: 400;
  margin-bottom: 0;
}

.rich-text-editor-description p {
  font-size: 80%;
  // font-weight: 400;
  margin-bottom: 0;
}

.rich-text-editor-370width {
  width: 370px
}

.rich-text-editor-435width {
  width: 435px
}
.rich-text-editor-480width {
  width: 480px
}

.ql-snow.ql-toolbar button {
  width: unset;
}
.ql-snow.ql-toolbar .ql-html {
  padding: unset;
  font-weight: 600;
}
.ql-toolbar .ql-html:after {
  content: "HTML";
}
.ql-editor .ql-html {
  background: #f7f7f7;
}

$grey: #eee;

.grey-bg {
  background-color: $grey;
}
.pointer {
  cursor: pointer;
}

.form-inline-date {
  display: flex;
  align-items: center;
}

.input-group-mobile {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  flex-wrap: unset;
}

.mobile-p-1 {
  padding: 10px;
}

.input-group-text-mobile {
  min-width: 80px;
  border: 0;
  background-color: #e9ecef;
}

.input-group-text-no-border {
  min-width: 80px;
  background-color: #e9ecef;
}

.image-option-selected.image-option-checked:hover {
  opacity: .8;
}

// .success-footer-buttons div:first-child {
//   overflow: scroll;  
// }

@media (max-width: 767.98px) { 
  .mobile-remove-mt {
    margin-top: 0 !important;
  }
  .mobile-remove-padding {
    padding: 0 !important;
  }
  .mobile-set-padding-15 {
    padding: 15px !important;
  }
}

.form-success-page .table-responsive {
  td:first-child, th {
    min-width: 40%;
  }
  td {
    white-space: normal;
  }
}

.image-validation-err {
  color: red;
}
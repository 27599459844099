.form-success-page {
  &:hover .question-toolbar {
    display: block;
  }
  .question-toolbar {
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
    .field-option-btn {
      cursor: pointer;
      border-radius: 14px;
      padding: 0px 6px;
      i {
        margin-left: 8px;
      }
    }
  }

  .quote-text {
    padding: 16px;
    margin: 10px auto;
    max-width: 450px;
    text-align: center;
  }
  .success-footer {
    margin: 20px 0px;
  }
  .success-footer-buttons {
    text-align: center;
  }
}

.success-page-options-panel {
  .display-order-table-option {
    float: right;
  }

  .display-order-table-label {
    padding-right: 1.2rem;
  }
  .display-order-table-input {
    margin-left: 0.2rem;
  }
}

.input-group .input-group-prepend .dropdown .dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-merge .input-group-prepend .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group .input-group-prepend .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

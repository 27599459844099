.billing-container .sub-col-header {
    color: rgb(117, 117, 117);
    text-transform: uppercase;
    font-size: 0.9rem;
}
.billing-container .sub-col-value {
    font-weight: bold;
}
.billing-container .sub-option {
    font-size: 0.8rem;
}